import React, { useState, useEffect } from "react";

export default ({
  label,
  callback,
  interval,
  showLabel,
  type,
  className,
}: {
  label: string;
  callback: (value: null | string) => void;
  interval?: number;
  showLabel?: boolean;
  type?: string;
  className?: string;
}) => {
  const [textInput, setTextInput] = useState<string | null>(null);

  useEffect(() => {
    const handle = setTimeout(() => callback(textInput), interval ?? 500);

    return () => clearTimeout(handle);
  }, [textInput, interval ?? 500]);

  const labelValue = label.toLowerCase().replace(" ", "_");

  return (
    <div className={className ?? ""}>
      {showLabel && (
        <label
          for={labelValue}
          class="block mb-2 text-sm font-medium text-black"
        >
          {label}
        </label>
      )}
      <input
        id={labelValue}
        className="border border-black text-black text-sm rounded-lg focus:ring-orange focus:border-orange block w-full p-2.5"
        type={type ?? "search"}
        onChange={(event) => setTextInput(event.target.value)}
        placeholder={label ?? ""}
      />
    </div>
  );
};
