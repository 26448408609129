import React, { useState, useEffect } from "react";

export default ({
  onChange,
  dateLabel,
  timeLabel,
}: {
  onChange: (_: string) => void;
  dateLabel: string | null;
  timeLabel: string | null;
}) => {
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    if (!(date && time)) return onChange("");

    const [hour, minute, ..._] = time.split(":");

    onChange(`${date}T${hour}:${minute}:00.000Z`);
  }, [date, time]);

  return (
    <div className="grid grid-rows-2 gap-y-2 w-full h-full">
      <div>
        {dateLabel && (
          <label htmlFor="datePicker" className="block">
            {dateLabel}
          </label>
        )}
        <input
          type="date"
          className="border border-black text-black text-sm rounded-lg focus:ring-orange focus:border-orange block w-full p-2.5"
          id="datePicker"
          onChange={(e) => setDate(e.target.value)}
          value={date}
        />
      </div>
      <div>
        {timeLabel && (
          <label htmlFor="timePicker" className="block">
            {timeLabel ?? "Time (UTC)"}
          </label>
        )}
        <input
          type="time"
          className="border border-black text-black text-sm rounded-lg focus:ring-orange focus:border-orange block w-full p-2.5"
          id="timePicker"
          disabled={date == null}
          onChange={(e) => setTime(e.target.value || "")}
          value={time}
        />
      </div>
    </div>
  );
};
