import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";

import {
  ArrowUpIcon,
  ArrowDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/solid";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import UserManagementModal from "../../components/UserManagementModal";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";

import Spinner from "../../components/Spinner";
import TextInput from "../../components/TextInput";

import { BAM_DOMAINS } from "../../constants";
import Dropdown from "../../components/Dropdown";

interface INorthstarUser {
  created: string;
  profile: {
    name: string;
    email: string;
  };
}

const UsersPage: React.FC = () => {
  const [userRegistrationActive, setUserRegistrationActive] =
    useState<boolean>(false);

  const [users, setUsers] = useState<INorthstarUser[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<string>("lastLogin");
  const [sortDirection, setSortDirection] = useState<number>(1);

  const [searchQuery, setSearchQuery] = useState("@");
  const [searchTerm, setSearchTerm] = useState("E-mail");

  const { accessToken, clearIdentity } = useAuthContext();

  const { data: whoami, error: whoAmIError } = useNorthstar(
    "/api/auth/whoami",
    accessToken,
    { method: "get" },
  );

  const getFilter = (): { [key: string]: string } => {
    let part = "";

    switch (searchTerm) {
      case "E-mail":
        part = "email";
        break;

      case "Given name":
        part = "firstName";
        break;

      case "Last name":
        part = "lastName";
        break;
    }

    const retval: { [key: string]: string } = {};

    if (searchQuery) retval[`filters[${part}][$containsi]`] = searchQuery;

    return retval;
  };

  const { data, isLoading, error } = useNorthstar("/api/persons", accessToken, {
    method: "get",
    params: {
      fields: [
        "firstName",
        "lastName",
        "email",
        "lastLogin",
        "activated",
        "scheduledActivation",
      ],
      sort: [`${sortField}:${sortDirection === 1 ? "asc" : "desc"}`, "id:asc"],
      "pagination[page]": page,
      "pagination[pageSize]": pageSize,
      ...getFilter(),
    },
  });

  useEffect(() => {
    let ignore = true;

    if ((whoAmIError?.status === 401 || error?.status === 401) && !ignore)
      clearIdentity();

    return () => {
      ignore = false;
    };
  }, [whoAmIError?.status, error?.status]);

  useEffect(() => {
    if (!whoami) return;

    const domain = whoami.username.split("@")[1];

    if (!BAM_DOMAINS.has(domain)) navigate("/");
  }, [whoami?.username]);

  useEffect(() => {
    if (!data) return;

    let ignore = false;

    const { data: people } = data;

    if (!ignore)
      setUsers(
        people.map(
          (person: {
            id: number;
            attributes: {
              firstName: string;
              lastName: string;
              email: string;
              lastLogin?: string;
              activated: boolean;
              scheduledActivation: string;
            };
          }) => ({
            id: person.id,
            email: person.attributes.email,
            firstName: person.attributes.firstName,
            lastName: person.attributes.lastName,
            lastLogin: person.attributes.lastLogin
              ? new Date(person.attributes.lastLogin).toString()
              : null,
            activated: person.attributes.activated,
            scheduledActivation: person.attributes.scheduledActivation,
          }),
        ),
      );

    return () => {
      ignore = true;
    };
  }, [data?.data]);

  const incrementPage = () => {
    setPage(page + 1);
  };
  const decrementPage = () => {
    setPage(page - 1);
  };

  const Table = ({ data, ...props }) => {
    return (
      <table className="border-collapse table-fixed w-full text-sm">
        <thead>
          <tr>
            {Object.entries(props).map((entry) => {
              const [key, title] = entry;

              let icon = <></>;

              if (key === sortField) {
                icon =
                  sortDirection === 1 ? (
                    <ArrowUpIcon className="h-4" />
                  ) : (
                    <ArrowDownIcon className="h-4" />
                  );
              }

              return (
                <th
                  className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"
                  onClick={() => {
                    setSortDirection(
                      key === sortField ? -1 * sortDirection : 1,
                    );
                    setSortField(key === "name" ? "firstName" : key);
                    setPage(1);
                  }}
                >
                  <div className="flex flex-row items-center">
                    {icon}
                    <strong>{title}</strong>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white">
          {data.map((datum) => (
            <tr>
              {Object.keys(props).map((key) => (
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                  {datum[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <NorthstarPageLayout title={"Admin"} showAdminBar={true}>
      <UserManagementModal
        visible={userRegistrationActive}
        setIsVisible={setUserRegistrationActive}
      />
      <>
        <Spinner active={isLoading} />
        {data && (
          <>
            <div className="mt-12">
              <div className="flex flex-row flex-center flex-grow space-x-4 mb-4">
                <div className="text-xl font-semibold">Users</div>
                <button
                  type="submit"
                  className="text-xs px-2 inline-flex items-center border border-transparent text-white font-medium rounded-full bg-blue hover:bg-orange cursor-pointer tracking-widest"
                  onClick={(e) => {
                    e.preventDefault();
                    setUserRegistrationActive(true);
                  }}
                >
                  Manage
                </button>
              </div>
              <div className="grid grid-cols-2 items-center">
                <div className="justify-self-start mb-4">
                  <div className="flex flex-row items-center">
                    <Dropdown
                      className="w-52"
                      dropdownText={`Search by: ${searchTerm.toString()}`}
                      dropDownOptions={[
                        "E-mail",
                        "Given name",
                        "Last name",
                      ].map((term) => {
                        return {
                          text: term.toString(),
                          onClick: () => {
                            setSearchTerm(term);
                            setPage(1);
                          },
                        };
                      })}
                    />
                    <TextInput
                      label={`Search ${searchTerm.toLowerCase()}`}
                      callback={(value) => {
                        setSearchQuery(value);
                        setPage(1);
                      }}
                    />
                  </div>
                </div>
                <div className="justify-self-end mb-4">
                  <div className="flex flex-row">
                    <Dropdown
                      className="w-28"
                      dropdownText={`Entries: ${pageSize.toString()}`}
                      dropDownOptions={[10, 25, 50, 100].map((size) => {
                        return {
                          text: size.toString(),
                          onClick: () => {
                            setPageSize(size);
                            setPage(1);
                          },
                        };
                      })}
                    />
                    <div className="flex">
                      <div className="grid grid-cols-2">
                        <div className="col-start-1 flex items-center">
                          {page === 1 ? (
                            <ChevronLeftIcon className="text-gray-light h-6" />
                          ) : (
                            <ChevronLeftIcon
                              className="text-black h-6 cursor-pointer"
                              onClick={decrementPage}
                            />
                          )}
                        </div>
                        <div className="col-start-2 flex items-center">
                          {page === data.meta?.pagination?.pageCount ? (
                            <ChevronRightIcon className="text-gray-light h-6" />
                          ) : (
                            <ChevronRightIcon
                              className="text-black h-6 cursor-pointer"
                              onClick={incrementPage}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative overflow-auto">
                <Table
                  email="E-mail"
                  firstName="Given name"
                  lastName="Last name"
                  lastLogin="Last log-in"
                  activated="Invited?"
                  scheduled="Scheduled?"
                  data={users.map((user) => ({
                    ...user,
                    activated: user.activated ? "Yes" : "No",
                    scheduled: user.scheduledActivation || "",
                  }))}
                />
              </div>
              <div className="p-2 flex">
                <div className="w-1/2">
                  <em>
                    {data.meta?.pagination?.pageCount === 0
                      ? "no results found"
                      : `page ${page} of ${data.meta?.pagination?.pageCount}`}
                  </em>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </NorthstarPageLayout>
  );
};

export default UsersPage;
