import React from "react";

interface ElevateButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  selected?: boolean;
  hoverState?: boolean;
  children: any;
  altColor?: boolean;
}

export const primaryButtonClassNames =
  "md:p-2 sm:px-1 py-1 bg-blue text-white tracking-widest rounded-full";

export const altButtonClassNames =
  "md:p-2 sm:px-1 py-1 bg-white text-blue tracking-widest rounded-full";

const ElevateButton: React.FC<ElevateButtonProps> = ({
  children,
  onClick = () => {},
  disabled = false,
  className = "",
  selected = false,
  altColor = false,
}) => {
  const baseClassNames =
    altColor === false ? primaryButtonClassNames : altButtonClassNames;

  return (
    <button
      type="button"
      aria-pressed={selected}
      disabled={disabled}
      onClick={onClick}
      className={`${
        selected ? "selected" : ""
      } ${baseClassNames} transition-colors ease-in-out ${
        disabled && "opacity-50"
      } ${className}`}
    >
      <div className="p-2 font-sans font-bold">{children}</div>
    </button>
  );
};

export default ElevateButton;
