import React, { useEffect, useState } from "react";
import axios from "axios";

import { useAuthContext } from "../../contexts/authContext";
import useNorthstar, { request } from "../../hooks/northstarHook";

import Modal from "../Modal";
import Spinner from "../Spinner";
import TextInput from "../TextInput";
import ElevateButton from "../ElevateButton";
import UTCDatetimePicker from "../UTCDatetimePicker";

export default ({
  visible,
  setIsVisible,
}: {
  visible: boolean;
  setIsVisible: (_: boolean) => void;
}) => {
  const enum UserCreationState {
    UNKNOWN = 0,
    FREE = 1,
    PROVISIONED = 2,
  }

  const [activationState, setActivationState] = useState<UserCreationState>(
    UserCreationState.UNKNOWN,
  );

  const [id, setID] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [activate, setActivate] = useState<boolean>(false);
  const [scheduledActivation, setScheduledActivation] = useState<string>("");

  const { accessToken } = useAuthContext();

  const {
    data,
    isLoading,
    error: apiError,
  } = useNorthstar("/api/persons", accessToken, {
    method: "get",
    params: {
      fields: [
        "id",
        "email",
        "firstName",
        "lastName",
        "activated",
        "scheduledActivation",
      ],
      "filters[email][$eq]": email,
      "pagination[page]": 1,
      "pagination[pageSize]": 1,
    },
  });

  useEffect(() => {
    if (isLoading) {
      setActivationState(UserCreationState.UNKNOWN);
    } else if (
      apiError ||
      !email ||
      !/^[^@]+@[a-zA-Z0-9][a-zA-Z0-9-]+(\.[a-zA-Z]+)+$/.test(email)
    ) {
      setActivationState(UserCreationState.UNKNOWN);

      if (apiError) {
        setError(apiError.error.message);
      } else if (email) {
        setError(`"${email}" is not a valid e-mail address`);
      }

      setID(null);
      setFirstName("");
      setLastName("");
      setActivate(false);
      setScheduledActivation("");
    } else if (!data?.data?.[0]?.id) {
      setActivationState(UserCreationState.FREE);
      setError("");
      setID(null);
      setFirstName("");
      setLastName("");
      setActivate(false);
      setScheduledActivation("");
    } else {
      const {
        data: [
          {
            id,
            attributes: {
              firstName,
              lastName,
              email,
              activated,
              scheduledActivation,
            },
          },
        ],
      } = data;

      setActivationState(UserCreationState.PROVISIONED);
      setError("");
      setID(id);
      setFirstName(firstName);
      setLastName(lastName);
      setActivate(activated);
      setScheduledActivation(scheduledActivation ?? "");
    }
  }, [email, data?.data?.[0]?.id]);

  const [error, setError] = useState<string>("");
  const [ok, setOK] = useState<string>("");

  const [resolving, setResolving] = useState<boolean>(false);

  const handleProvision = (event) => {
    event.preventDefault();

    setResolving(true);

    request("/api/persons", accessToken, {
      method: "post",
      data: {
        data: {
          firstName,
          lastName,
          email,
          activated: scheduledActivation ? false : activate,
          scheduledActivation: scheduledActivation || null,
        },
      },
    })
      .then(
        ({
          data: {
            data: {
              id,
              attributes: { firstName, lastName },
            },
          },
        }) => {
          setOK(
            `Success! We've provisioned user ID ${id} for ${firstName} ${lastName}.`,
          );
        },
      )
      .catch((reason) => {
        if (!axios.isAxiosError(reason)) throw reason;
        setError(reason?.response?.data?.error?.message ?? "Unknown error.");
      })
      .finally(() => {
        setEmail("");
        setResolving(false);
        setActivationState(UserCreationState.UNKNOWN);
        setActivate(false);
        setScheduledActivation("");

        setTimeout(() => {
          setOK("");
          setError("");
        }, 1_000);
      });
  };

  const handleUpdate = (event, endpoint) => {
    event.preventDefault();

    setResolving(true);

    request(endpoint, accessToken, {
      method: "post",
      data: null,
    })
      .then((value) => {
        setOK(`Success! We've updated user ID ${id} for ${email}.`);
      })
      .catch((reason) => {
        if (!axios.isAxiosError(reason)) throw reason;

        setError(reason?.response?.data?.error?.message ?? "Unknown error.");
      })
      .finally(() => {
        setEmail("");
        setResolving(false);
        setActivationState(UserCreationState.UNKNOWN);
        setActivate(false);
        setScheduledActivation("");

        setTimeout(() => {
          setOK("");
          setError("");
        }, 1_000);
      });
  };

  let context = (
    <p>
      <strong>Welcome! </strong>
      Enter an e-mail address below.
    </p>
  );

  switch (activationState) {
    case UserCreationState.FREE:
      context = (
        <p>
          <strong>Invite a new user.</strong> Enter their details below.
        </p>
      );
      break;

    case UserCreationState.PROVISIONED:
      context = (
        <p>
          <strong>Manage an existing user. </strong>You can change alter a
          user's state below.
        </p>
      );
      break;
  }

  if (ok) {
    return (
      <Modal visible={visible} setIsVisible={setIsVisible}>
        <div className="flex flex-col gap-y-5">
          <p>
            <strong>Success!</strong>
          </p>
          <hr />
          <p className="mt-4 text-blue text-center">{ok}</p>
        </div>
      </Modal>
    );
  } else
    return (
      <Modal visible={visible} setIsVisible={setIsVisible}>
        {resolving && <Spinner active={true} />}
        {!resolving && (
          <>
            <div className="flex flex-col gap-y-5">
              {ok ? (
                <strong>Success!</strong>
              ) : error ? (
                <strong>Oops!</strong>
              ) : (
                context
              )}
              <hr />
              <div>
                <TextInput
                  label="E-mail address"
                  callback={setEmail}
                  showLabel={false}
                />
                {error && (
                  <p className="text-dark-orange text-sm text-right">{error}</p>
                )}
              </div>
              {activationState === UserCreationState.FREE && (
                <>
                  <div className="grid grid-cols-2">
                    <TextInput
                      className="mr-2"
                      label="Given name"
                      callback={setFirstName}
                      showLabel={false}
                    />
                    <TextInput
                      label="Last name"
                      callback={setLastName}
                      showLabel={false}
                    />
                  </div>
                  <div className="grid grid-cols-[80%_20%]">
                    <p>Should we invite this user?</p>
                    <div className="content-center">
                      <input
                        className="mr-2"
                        type="checkbox"
                        checked={activate}
                        onChange={() => {
                          setActivate(!activate);
                          setScheduledActivation("");
                        }}
                      />
                    </div>
                  </div>
                  {activate && (
                    <>
                      <p>
                        Should we schedule the invitation for a specific date
                        and time (UTC)?
                      </p>
                      <UTCDatetimePicker onChange={setScheduledActivation} />
                    </>
                  )}
                  <ElevateButton
                    className="self-center"
                    disabled={!(email && firstName && lastName)}
                    onClick={handleProvision}
                  >
                    {scheduledActivation
                      ? "Schedule invite"
                      : activate
                        ? "Invite now"
                        : "Provision"}
                  </ElevateButton>
                </>
              )}
              {activationState === UserCreationState.PROVISIONED && (
                <>
                  <p>
                    {firstName} {lastName} ({email}) is already{" "}
                    {activate
                      ? "activated"
                      : scheduledActivation
                        ? "scheduled for activation"
                        : "provisioned"}
                    .
                  </p>
                  <ElevateButton
                    className="self-center"
                    disabled={!id}
                    onClick={(event) => {
                      handleUpdate(
                        event,
                        `/api/persons/${id}/lifecycle/` +
                          (scheduledActivation || activate
                            ? "deactivate"
                            : "activate"),
                      );
                    }}
                  >
                    {scheduledActivation
                      ? "Cancel invite"
                      : activate
                        ? "Uninvite"
                        : "Invite now"}
                  </ElevateButton>
                </>
              )}
            </div>
          </>
        )}
        {ok && <p className="mt-4 text-blue text-center">{ok}</p>}
      </Modal>
    );
};
